var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var eraDataList = [{
  code: "reiwa",
  firstDate: "2019-05-01",
  name: "\u4EE4\u548C"
}, {
  code: "heisei",
  firstDate: "1989-01-08",
  name: "\u5E73\u6210"
}, {
  code: "showa",
  firstDate: "1926-12-25",
  name: "\u662D\u548C"
}, {
  code: "taisho",
  firstDate: "1912-07-30",
  name: "\u5927\u6B63"
}, {
  code: "meiji",
  firstDate: "1868-01-25",
  name: "\u660E\u6CBB"
}];

function _default() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Date.now();
  var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var dateObj = new Date(value);
  var year = dateObj.getFullYear();

  if (isNaN(year)) {
    return year;
  }

  var wareki = "".concat(year);
  var _arr = eraDataList;

  for (var _i = 0; _i < _arr.length; _i++) {
    var eraData = _arr[_i];
    var firstDate = eraData.firstDate;
    var name = eraData.name;
    var eraFirstDateObj = new Date(firstDate);

    if (dateObj.getTime() - eraFirstDateObj.getTime() >= 0) {
      var eraYear = "".concat(year - eraFirstDateObj.getFullYear() + 1);

      if (eraYear === "1") {
        eraYear = "\u5143";
      }

      wareki = "".concat(name).concat(eraYear);
      break;
    }
  }

  if (opts.unit) {
    wareki += "\u5E74";
  }

  if (!isNaN(Number(wareki))) {
    return Number(wareki);
  }

  return wareki;
}

exports = exports.default;
export default exports;
export const __esModule = exports.__esModule;